import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'
import SEO from "../components/SEO"
import Banner from '../components/Banner'
import { defaultTheme } from '../styles'
import TextSection from '../components/TextSection'
import TestimonialsSlider from '../components/TestimonialsSlider'
import Image from 'gatsby-image'

const AboutPage = () => {

    const data = useStaticQuery(graphql`
    {
      allFile(filter: {absolutePath: {regex: "/images/"}}) {
        nodes {
          name
          id
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const allImages = data.allFile.nodes; 

    return (
        <Layout>
          <div>
            <SEO
              title="Sexual Abuse Law Firm | Sex Abuse Attorney"
              metaDesc="A California Law Firm Devoted to Representing Victims of California Sex Abuse."
            />
            <Banner
                eyeBrowCopy="Firm Overview"
                headingCopy="About Craig Charles Law"
                // imageSrc={allImages.find(node => node.name === "craig-charles-sexual-abuse-lawyer").childImageSharp.fluid}
            />
            <TextSection
              bgColor={`${defaultTheme.darkBlue}`}
              textColor={`${defaultTheme.white}`}
              modifiers={['hasCols']}
            >
              <div className="cols">
                <div className={`imagecol`}>
                  <Image fluid={allImages.find(node => node.name === "craig-charles-sexual-abuse-lawyer").childImageSharp.fluid} alt={`Craig Charles Headshot`} />
                </div>
                <div className={`textcol`}>
                  <h2>A California Law Firm Devoted to Representing Victims of California Sex Abuse</h2>
                  <p>
                    Craig Charles focuses solely on catastrophic injuries and child sex abuse claims in California. This limited focus gives him keen insight, skill, and knowledge to achieve top results in every case. With more than a decade of litigation and trial experience, Mr. Charles has recovered multiple millions of dollars on behalf of injured and sexually abused victims in California.
                  </p>
                  <p>
                  Mr. Charles believes firmly in the concept of a boutique law firm. Unlike large volume law firms, in which clients and their cases are often neglected, Craig Charles represents only a limited number of injury and child sex abuse victims. This approach allows him to not only give each client individual attention, but to also maximize the value of each case. Mr. Charles only takes cases where he believes a severe injustice has occurred. This allows him to be passionate about every case and to fight zealously on behalf of each client. The net result is a huge success, as Mr. Charles obtains numerous multi-million-dollar resolutions for clients year after year.
                  </p>
                </div>
              </div>
            </TextSection>
          </div>  
            <TextSection>
              <p>
                Mr. Charles is particularly proud of his work helping clients to recover from abuse at the hands of church leaders, scout leaders, teachers, coaches and counselors. His vast knowledge and legal training in this area of the law gives him an upper hand in achieving large monetary awards. However, Mr. Charles’s greatest satisfaction is experiencing, firsthand, the self- empowerment and sense of closure his clients gain in the process.
              </p>
              <p>
                In addition to helping victims of injury and abuse, Mr. Charles actively participates in several legal organizations that further the interests of injured people. He is a member of the Consumer Advocate Attorneys of Los Angeles, Consumer Attorneys of California and the American Association for Justice.
              </p>
              <p>
                In his spare time, Mr. Charles is an active member of several organizations, including Equality California, the Trevor Project, the Human Rights Campaign and Trial Lawyer’s Charities. Mr. Charles also has a strong interest in historic preservation, and he previously served as a Commissioner on the West Hollywood Historic Preservation Commission.
              </p>
              <p>
                Mr. Charles lives in Manhattan Beach with his partner and three stepchildren.
              </p>
              <p>
                Craig Charles has three office locations to serve client’s particular needs: West Hollywood, El Segundo and San Diego. Additionally, Mr Charles is licensed to practice in both state and federal courts in California.
              </p>
            </TextSection>
            <TestimonialsSlider />
        </Layout>
    )
}

export default AboutPage
